declare let window;

export function getCookie(c_name: string) {
  if (typeof window === "undefined") {
    return null;
  }

  let c_value: string = " " + (window?.document?.cookie || "");
  let c_start = c_value.indexOf(" " + c_name + "=");
  if (c_start == -1) {
    c_value = "";
  } else {
    c_start = c_value.indexOf("=", c_start) + 1;
    let c_end = c_value.indexOf(";", c_start);
    if (c_end == -1) {
      c_end = c_value.length;
    }
    c_value = unescape(c_value.substring(c_start, c_end));
  }
  return c_value;
}

export function deleteAllCookies() {
  const res = document.cookie;
  const multiple = res.split(";");
  for (let i = 0; i < multiple.length; i++) {
    const key = multiple[i].split("=");
    document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  }
}

export function toTitleCase(str: string | undefined): string {
  if (!str) {
    return "";
  }
  str = str.split("_").join(" ");
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function commaSeparated(
  str: string | undefined,
  divider: string,
): string {
  if (!str) {
    return "";
  }
  return str.split(",").join(divider);
}

export function shallowCompare(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function largeNumber(number: number): string {
  if (number >= 1000000) {
    if (number >= 10000000) {
      return `${(number / 1000000).toFixed(0)}m`;
    } else {
      return `${(number / 1000000).toFixed(1)}m`;
    }
  }

  if (number >= 1000) {
    if (number >= 10000) {
      return `${(number / 1000).toFixed(0)}k`;
    } else {
      return `${(number / 1000).toFixed(1)}k`;
    }
  }

  return number.toFixed(0);
}

export const getBase64 = async (file): Promise<string | undefined> => {
  if (!file) {
    return;
  }

  const reader = new FileReader();
  reader.readAsDataURL(file as Blob);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as any);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const urlToFile = (base64, filename, mimeType) => {
  return fetch(base64)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
