import { useObservable } from "react-use";
import React, { useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1199,
    flexGrow: 1,
  },

  toolbar: {
    position: "relative",
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    boxShadow: "-1px 3px 3px #888888",
  },

  toolbarLeft: {
    display: "flex",
    alignItems: "center",
  },

  logo: {
    maxWidth: "120px",
    maxHeight: "50px",
    marginRight: "20px",
  },

  appBar: {
    position: "fixed",
    left: 0,
    zIndex: 101,
  },

  // drawerClosed: {
  //   width: '100%',
  //   transition: theme.transitions.create('all', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  // },

  // drawerOpened: {
  //   width: (config: { drawerOffset: number }) =>
  //     `calc(100% - ${config.drawerOffset}px)`,
  //   transition: theme.transitions.create('all', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },

  input: {
    minWidth: "200px",
    background: "white",
    borderRadius: "6px",
  },

  titleContainer: {
    display: "flex",
    flexDirection: "column",
  },

  title: {
    flexGrow: 1,
    marginRight: 10,
  },

  siteContent: {
    opacity: 0.6,
  },

  titleHidden: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  searchContainer: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  hamburger: {
    marginLeft: 30,
    cursor: "pointer",
  },

  appBarOffset: {
    height: 65,
  },

  toolbarSub: {
    padding: "0px 15px 0px 15px",
    color: theme.palette.text.primary,
    background: "#eeeeee",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  ul: {
    display: "flex",
    flexWrap: "nowrap",
    margin: 0,
    padding: 0,
  },

  li: {
    position: "relative",
    marginRight: 10,
    padding: "5px 10px 0px 10px",
    transition: ".3s",

    "&:hover": {
      color: "#ffffff",
      background: "#2a2a2a",
    },

    "&:hover ul": {
      display: "flex",
    },
  },

  subcategoryContainer: {
    position: "absolute",
    left: 0,
    display: "flex",
    width: "max-content",
    padding: "0px 10px 0px 0px",
    background: "#2a2a2a",
  },

  subcategory: {
    minWidth: 100,
    padding: "10px 0px 10px 10px",
    color: "#eeeeee",
  },

  subcategoryText: {
    display: "inline-block",
    color: "hsla(0,0%,100%,.6)",
    fontWeight: 600,
    cursor: "pointer",

    "&:hover": {
      color: "#ffffff",
    },
  },
}));

const HomeNavigation = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.appBarOffset}></div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <NavLink to={"/"}>
              <img src="/img/logo_white.png" className={classes.logo} alt="" />
            </NavLink>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HomeNavigation;
