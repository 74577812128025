import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { lazy, useMemo } from "react";
import { useObservable } from "react-use";
import DocumentTitle from "react-document-title";
import makeStyles from "@mui/styles/makeStyles";

import { authService } from "services/Auth";
import { getPageTitle } from "lib/pageTitle";

import CheckPermissions from "modules/shared/Atoms/CheckPermissions";
import Footer from "modules/shared/Atoms/Footer";
import Home from "modules/home/Pages/Home";
import HomeNavigation from "modules/home/Organisms/HomeNavigation";
import Loading from "modules/shared/Pages/Loading";
import LoadingState from "modules/shared/Organisms/LoadingState";
import Login from "modules/login/Pages/Login";

import Snackbar from "modules/shared/Atoms/Snackbar";

const NotFound = lazy(() => import("modules/notFound/Pages/NotFound"));
const Signup = lazy(() => import("modules/login/Pages/Signup"));
const Logout = lazy(() => import("modules/login/Pages/Logout"));
const Plugin = lazy(() => import("modules/plugin/Pages/Plugin"));
const Dashboard = lazy(() => import("modules/dashboard/Pages/Dashboard"));
const FeaturedGroup = lazy(
  () => import("modules/featured-group/Pages/FeaturedGroup"),
);
const Profile = lazy(() => import("modules/profile/Pages/Profile"));
const Admin = lazy(() => import("modules/admin/Pages/Admin"));
const Blueprint = lazy(() => import("modules/blueprint/Pages/Blueprint"));

import * as roleConstants from "constants/roles";
import * as routingConstants from "lib/routing";

const useStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100vh",
    background: "#f8f8f8",
  },
}));

const Main = () => {
  const classes = useStyles();
  const history = useHistory();
  const params: Record<string, string> = useParams();
  const auth$ = useMemo(() => {
    return authService.fetchAuth().pipe(
      catchError((e) => {
        return of({});
      }),
    );
  }, []);
  const returned = useObservable(auth$);

  if (!returned) {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }

  return (
    <div className={classes.app}>
      <div>
        <HomeNavigation />
        <LoadingState />
        <Switch>
          <Route path={routingConstants.ROUTE_DASHBOARD}>
            <CheckPermissions
              permissions={[
                roleConstants.PERMISSIONS_HUB_ADMIN,
                roleConstants.PERMISSIONS_HUB_USER,
              ]}
              redirectTo={"/login"}
              matchAny={true}
            >
              <DocumentTitle title={getPageTitle("Dashboard")}>
                <Dashboard />
              </DocumentTitle>
            </CheckPermissions>
          </Route>
          <Route path={"/admin"}>
            <Admin />
          </Route>
          <Route path={routingConstants.ROUTE_BLUEPRINTS_PREFIX}>
            <Blueprint />
          </Route>
          <Route path={routingConstants.ROUTE_SIGNUP}>
            <DocumentTitle title={getPageTitle("Sign Up")}>
              <Signup />
            </DocumentTitle>
          </Route>
          <Route path={routingConstants.ROUTE_LOGIN}>
            <DocumentTitle title={getPageTitle("Login")}>
              <Login />
            </DocumentTitle>
          </Route>
          <Route path={routingConstants.ROUTE_LOGOUT}>
            <Logout />
          </Route>
          <Route path={routingConstants.ROUTE_PLUGINS_PREFIX}>
            <DocumentTitle title={getPageTitle("Plugins")}>
              <Plugin />
            </DocumentTitle>
          </Route>
          <Route path={routingConstants.ROUTE_FEATURED_GROUP_PREFIX}>
            <DocumentTitle title={getPageTitle("Featured")}>
              <FeaturedGroup />
            </DocumentTitle>
          </Route>
          <Route path={routingConstants.ROUTE_PROFILES_PREFIX}>
            <DocumentTitle title={getPageTitle("Profiles")}>
              <Profile />
            </DocumentTitle>
          </Route>
          <Route
            exact
            path={[
              routingConstants.ROUTE_MAIN,
              routingConstants.ROUTE_MAIN_PAGE_CATEGORY,
              routingConstants.ROUTE_MAIN_PAGE_SUBCATEGORY,
            ]}
          >
            <DocumentTitle title={getPageTitle("Home")}>
              <Home />
            </DocumentTitle>
          </Route>
          <Route path={routingConstants.ROUTE_NOT_FOUND}>
            <DocumentTitle title={getPageTitle("Not Found")}>
              <NotFound />
            </DocumentTitle>
          </Route>
          <Route path={"*"}>
            <Redirect to={routingConstants.ROUTE_NOT_FOUND} />
          </Route>
        </Switch>
        <Snackbar />
      </div>
      <Footer />
    </div>
  );
};

export default Main;
