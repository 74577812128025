import { Subject } from "rxjs";
import { useObservable } from "react-use";
import React, { ReactElement, useCallback } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";

export const snack$: Subject<{
  show: boolean;
  message: string;
  type?: "success" | "error" | "warning" | "info" | "primary" | "loading";
}> = new Subject();

const useStyles = makeStyles((theme) => {
  return {
    mobileSnackbar: {
      "&.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter": {
        bottom: 90,
      },
    },

    snackbar: {
      display: "flex",

      background: "#ffffff",
      boxShadow: ({ type }: { type: string }) =>
        ({
          success: `1px 1.5px 3px -.5px ${theme.palette.success.main}`,
          error: `0 2px 3px 0 ${theme.palette.error.main}`,
          info: `0 2px 3px 0 ${theme.palette.info.main}`,
          loading: `0 2px 3px 0 ${theme.palette.info.main}`,
          warning: `0 2px 3px 0 ${theme.palette.warning.main}`,
          primary: `0 2px 3px 0 ${theme.palette.primary.main}`,
        })[type] || "#fff",
      borderLeft: ({ type }: { type: string }) =>
        ({
          success: `6px solid ${theme.palette.success.main}`,
          error: `5px solid ${theme.palette.error.main}`,
          info: `5px solid ${theme.palette.info.main}`,
          loading: `5px solid ${theme.palette.info.main}`,
          warning: `5px solid ${theme.palette.warning.main}`,
          primary: `5px solid ${theme.palette.primary.main}`,
        })[type] || "#fff",
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },

    result: {
      fontWeight: 600,
      paddingRight: 10,
    },

    success: {
      color: theme.palette.success.main,
    },

    info: {
      color: theme.palette.info.main,
    },

    error: {
      color: theme.palette.error.main,
    },

    warning: {
      color: theme.palette.warning.main,
    },

    primary: {
      color: theme.palette.warning.main,
    },

    messageContainer: {
      display: "flex",
      alignItems: "center",
      minWidth: 200,
      padding: 15,
      backgroundColor: "none",
    },

    message: {
      textAlign: "center",
      wordBreak: "break-word",
      backgroundColor: "none",
    },

    loading: {
      width: 20,
      height: 20,
      marginLeft: 15,

      "& .MuiCircularProgress-svg": {
        color: theme.palette.info.main,
      },
    },

    closeIcon: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      paddingLeft: 15,
      cursor: "pointer",
    },
  };
});

const renderType = (type: string, classes) => {
  switch (type) {
    case "success":
      return (
        <span className={clsx(classes.result, classes.success)}>Success!</span>
      );
    case "error":
      return (
        <span className={clsx(classes.result, classes.error)}>Error:</span>
      );
    case "warning":
      return (
        <span className={clsx(classes.result, classes.warning)}>Warning:</span>
      );
    case "info":
      return (
        <span className={clsx(classes.result, classes.info)}>
          Notification:
        </span>
      );
    case "loading":
      return (
        <span className={clsx(classes.result, classes.info)}>Loading:</span>
      );
    default:
      return <></>;
  }
};

const Snack = () => {
  const config = useObservable(snack$);
  const classes = useStyles({
    type: config?.type || "",
  });

  const handleClose = useCallback(() => {
    snack$.next({
      show: false,
      message: config?.message || "",
      type: config?.type,
    });
  }, [config]);

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      open={config?.show}
      autoHideDuration={5000}
      onClose={handleClose}
      key={"snackbar"}
    >
      <div className={clsx(classes.snackbar)}>
        <div className={classes.messageContainer}>
          <div className={classes.message}>
            {renderType(config?.type || "", classes)}
            {config?.message || ""}
          </div>
          {config?.type === "loading" && (
            <CircularProgress className={classes.loading} />
          )}
          {config?.type !== "loading" && (
            <div onClick={handleClose} className={classes.closeIcon}>
              <CloseIcon />
            </div>
          )}
        </div>
      </div>
    </Snackbar>
  );
};

export default Snack;
