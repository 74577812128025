import { AjaxResponse, ajax } from "rxjs/ajax";
import { Observable, from, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { AppConfig } from "../config";
import { getCookie } from "lib/functions";
import { sessionQuery } from "state/session/query";

export const getMultipartHeaders = (headers = {}) => {
  return {
    ...headers,
    [AppConfig.csrfHeader]: getCookie(AppConfig.siteCsrfCookieName),
  };
};

export const getRawHeaders = (headers = {}) => {
  return {
    ...headers,
    [AppConfig.csrfHeader]: getCookie(AppConfig.siteCsrfCookieName),
    "content-type": "application/octet-stream",
  };
};

export const getHeaders = (headers = {}) => {
  return {
    ...headers,
    [AppConfig.csrfHeader]: getCookie(AppConfig.siteCsrfCookieName),
    "content-type": "application/json",
  };
};

export function get<T>(
  url: string,
  config?: {
    body: any;
    headers: any;
    timeout?: number;
  },
): Observable<T> {
  const headers = getHeaders({
    ...(config?.headers || {}),
  });

  return ajax({
    method: "GET",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    timeout: config?.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function post<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    timeout?: number;
  },
): Observable<T> {
  const { body } = config;

  const headers = getHeaders({
    ...(config.headers || {}),
  });

  return ajax({
    method: "POST",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function postMultipart<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    timeout?: number;
  },
): Observable<T> {
  const { body } = config;

  const headers = getMultipartHeaders({
    ...(config.headers || {}),
  });

  return ajax({
    method: "POST",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function postRaw<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    filename?: string;
    timeout?: number;
  },
): Observable<T> {
  const { body, filename } = config;

  const headers = getRawHeaders({
    ...(config.headers || {}),
  });

  return ajax({
    method: "POST",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function put<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    timeout?: number;
  },
): Observable<T> {
  const { body } = config;

  const headers = getHeaders({
    ...(config.headers || {}),
  });

  return ajax({
    method: "PUT",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function _delete<T>(
  url: string,
  config?: {
    headers: any;
    timeout?: number;
  },
): Observable<T> {
  const headers = getHeaders({
    ...(config?.headers || {}),
  });

  return ajax({
    method: "DELETE",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    timeout: config?.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export interface ApiResponse<T> {
  result: boolean;
  data: T;
}

export const PAGINATION_PAGE_PARAM = "currentPage";
export const PAGINATION_PER_PAGE_PARAM = "perPage";
export const PAGINATION_COUNT_ONLY_PARAM = "countOnly";
export const PAGINATION_SEARCH_PARAM = "search";
