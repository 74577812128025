import { concat } from "lodash-es";
import { sessionStore } from "state/session/store";

import {
  AuthState,
  BannerState,
  ChartState,
  ContactState,
  CredentialState,
  DashboardState,
  DownloadHistoryState,
  FeaturedGroupState,
  FeaturedInvitationState,
  FeaturedThreadItemState,
  FeaturedThreadReleaseState,
  FeaturedThreadState,
  HubPluginState,
  ImageState,
  MainPaginationState,
  ModalState,
  PipelineFlowState,
  PipelineGroupState,
  PipelineJobState,
  PipelineState,
  ProfileState,
  RepositoryHistoryState,
  RepositoryItemState,
  RepositoryState,
  SettingsState,
  TileState,
  TrendingState,
  UiState,
  UpstreamFeaturedThreadState,
  UpstreamPipelineState,
  UserState,
} from "./interface";
import { SessionState } from "./store";
import { sessionQuery } from "state/session/query";

import * as entryConstants from "constants/entries";

export class SessionService {
  constructor() {
    this.loadLocalStorageBookmarks();
    this.loadLocalStorageBookmarksRemoved();
  }

  loadLocalStorageBookmarks() {
    try {
      const storage = localStorage.getItem(entryConstants.BOOKMARKS);
      const bookmarks = JSON.parse(storage || "[]");
      if (Array.isArray(bookmarks)) {
        this.updateUi({
          bookmarks,
        });
      }
    } catch (e) {
      // No values to use.
    }
  }

  loadLocalStorageBookmarksRemoved() {
    try {
      const storage = localStorage.getItem(entryConstants.BOOKMARKS_REMOVED);
      const bookmarksRemoved = JSON.parse(storage || "[]");
      if (Array.isArray(bookmarksRemoved)) {
        this.updateUi({
          bookmarksRemoved,
        });
      }
    } catch (e) {
      // No values to use.
    }
  }

  setLoading(state: boolean) {
    sessionStore.setLoading(state);
  }

  setError(state: boolean) {
    sessionStore.setError(state);
  }

  updateAuth(config: Partial<AuthState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        auth: {
          ...session.auth,
          ...config,
        },
      };
    });
  }

  updateUi(config: Partial<UiState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        ui: {
          ...session.ui,
          ...config,
        },
      };
    });
  }

  updateModal(config: Partial<ModalState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        modal: {
          ...session.modal,
          ...config,
        },
      };
    });
  }

  updateDownloadHistory(config: Partial<DownloadHistoryState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        downloadHistory: {
          ...session.downloadHistory,
          ...config,
        },
      };
    });
  }

  updateMainPagination(config: Partial<MainPaginationState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        mainPagination: {
          ...session.mainPagination,
          ...config,
        },
      };
    });
  }

  updateImages(config: Partial<ImageState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        images: {
          ...session.images,
          ...config,
        },
      };
    });
  }

  updateRepositories(config: Partial<RepositoryState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        repositories: {
          ...session.repositories,
          ...config,
        },
      };
    });
  }

  updateRepositoryHistory(config: Partial<RepositoryHistoryState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        repositoryHistory: {
          ...session.repositoryHistory,
          ...config,
        },
      };
    });
  }

  updateRepositoryItems(config: Partial<RepositoryItemState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        repositoryItems: {
          ...session.repositoryItems,
          ...config,
        },
      };
    });
  }

  updateProfile(config: Partial<ProfileState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        profile: {
          ...session.profile,
          ...config,
        },
      };
    });
  }

  updateSettings(config: Partial<SettingsState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        settings: {
          ...session.settings,
          ...config,
        },
      };
    });
  }

  updateContacts(config: Partial<ContactState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        contacts: {
          ...session.contacts,
          ...config,
        },
      };
    });
  }

  updatePipelines(config: Partial<PipelineState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        pipelines: {
          ...session.pipelines,
          ...config,
        },
      };
    });
  }

  updatePipelineFlows(config: Partial<PipelineFlowState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        pipelineFlows: {
          ...session.pipelineFlows,
          ...config,
        },
      };
    });
  }

  updatePipelineJobs(config: Partial<PipelineJobState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        pipelineJobs: {
          ...session.pipelineJobs,
          ...config,
        },
      };
    });
  }

  updateDashboard(config: Partial<DashboardState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        dashboard: {
          ...session.dashboard,
          ...config,
        },
      };
    });
  }

  updateSelfFeaturedGroups(config: Partial<FeaturedGroupState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        selfFeaturedGroups: {
          ...session.selfFeaturedGroups,
          ...config,
        },
      };
    });
  }

  updateGeneralFeaturedGroups(config: Partial<FeaturedGroupState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        generalFeaturedGroups: {
          ...session.generalFeaturedGroups,
          ...config,
        },
      };
    });
  }

  updateSubscribedFeaturedGroups(config: Partial<FeaturedGroupState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        subscribedFeaturedGroups: {
          ...session.subscribedFeaturedGroups,
          ...config,
        },
      };
    });
  }

  updateFeaturedThreads(config: Partial<FeaturedThreadState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        featuredThreads: {
          ...session.featuredThreads,
          ...config,
        },
      };
    });
  }

  updateFeaturedThreadReleases(config: Partial<FeaturedThreadReleaseState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        featuredThreadReleases: {
          ...session.featuredThreadReleases,
          ...config,
        },
      };
    });
  }

  updateFeaturedThreadItems(config: Partial<FeaturedThreadItemState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        featuredThreadItems: {
          ...session.featuredThreadItems,
          ...config,
        },
      };
    });
  }

  updateFeaturedInvitations(config: Partial<FeaturedInvitationState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        featuredInvitations: {
          ...session.featuredInvitations,
          ...config,
        },
      };
    });
  }

  updateUsers(config: Partial<UserState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        users: {
          ...session.users,
          ...config,
        },
      };
    });
  }

  updateUpstreamFeaturedThreads(config: Partial<UpstreamFeaturedThreadState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        upstreamFeaturedThreads: {
          ...session.upstreamFeaturedThreads,
          ...config,
        },
      };
    });
  }

  updateUpstreamPipelines(config: Partial<UpstreamPipelineState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        upstreamPipelines: {
          ...session.upstreamPipelines,
          ...config,
        },
      };
    });
  }

  updateCredentials(config: Partial<CredentialState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        credentials: {
          ...session.credentials,
          ...config,
        },
      };
    });
  }

  updateTiles(config: Partial<TileState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        tiles: {
          ...session.tiles,
          ...config,
        },
      };
    });
  }

  updateCharts(config: Partial<ChartState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        charts: {
          ...session.charts,
          ...config,
        },
      };
    });
  }

  updatePipelineGroups(config: Partial<PipelineGroupState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        pipelineGroups: {
          ...session.pipelineGroups,
          ...config,
        },
      };
    });
  }

  updateBanners(config: Partial<BannerState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        banners: {
          ...session.banners,
          ...config,
        },
      };
    });
  }

  updateTrending(config: Partial<TrendingState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        trending: {
          ...session.trending,
          ...config,
        },
      };
    });
  }

  updateHubPlugins(config: Partial<HubPluginState>) {
    sessionStore.update((session): SessionState => {
      return {
        ...session,
        hubPlugins: {
          ...session.hubPlugins,
          ...config,
        },
      };
    });
  }
}

export const sessionService = new SessionService();
