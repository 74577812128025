import { useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, { useMemo } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { AppConfig } from "config";
import { getCookie } from "lib/functions";
import { isLoggedIn$ } from "state/session/query";
import { theme$ } from "state/site/query";

import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: (props: any) => "#eeeeee",
  },

  logoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 90,
    height: 90,
    transform: "translate(-50%, -50%)",
  },

  logo: {
    animationDuration: "2s",
    animationIterationCount: "infinite",
  },

  roller: {
    color: "#232323",

    "& div:after": {
      background: "#232323",
    },
  },
}));

export default function Loading() {
  const theme = useObservable(theme$);

  const classes = useStyles({});

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Fade in={true} timeout={250}>
          <div className={classes.logoContainer}>
            <div className={clsx("lds-roller", classes.roller)}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Fade>
      </Grid>
    </Grid>
  );
}
