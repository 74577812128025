"use client";

import AppWrapper from "wrapper";

import Main from "../main";

const Home = () => {
  return (
    <AppWrapper>
      <Main />
    </AppWrapper>
  );
};

export default Home;
