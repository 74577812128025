// You cannot use TS special path imports here!

export const signupHost = "hub.wordparrot.com";

export const primaryMain = "#359fff";
export const primaryDark = "#1d78e6";
export const primaryLight = "#5EB3FF";
export const primaryContrastText = "#ffffff";

export const bgBar = "#0e2d69";
export const contrastText = "#bbbbbb";

export const secondaryMain = "#ea9210";
export const secondaryDark = "#ff5555";
export const secondaryLight = "#ff5555";
export const secondaryContrastText = "#ffffff";

export const info = "#0080FF";
export const success = "#08a508";
export const error = "#c3000f";
export const warning = "#dbbd0b";

export const textPrimary = "#717788";
export const textSecondary = "#bbbbbb";
export const textDisabled = "#dddddd";

export const backgroundPaper = "#ffffff";
export const backgroundDefault = "#f7f7f7";

export const legibilityHigh = "#121c39";
export const legibilityMed = "#717788";
export const legibilityLow = "#a5a9b4";

export const divider = "#cccccc";
export const footer = "#eeeeee";

export const type: any = "light";

export const DefaultSite = {
  id: "1",
  domain: signupHost,
  defaultSubdomain: signupHost,
  domainApproved: true,
  authScheme: "public",
  title: "Wordparrot Sites",
  organization: "Wordparrot",
  logo: "/img/parrot_sm.png",
  logoApproved: true,
  favicon: "",
  dns: {
    id: "",
    isSubdomain: true,
    verified: false,
    txtChallengeKey: "",
    txtChallengeValue: "",
  },
  images: [],
  seo: {
    titleTag: "Wordparrot Portal | Home",
    metaTitle: "Wordparrot Portal",
    metaDescription:
      "Register a new site today and start building your branded media library. Fully customizable and hosted on your very own domain.",
    metaImage: "/img/parrot_sm.png",
    metaFavicon: "/favicon.ico",
  },
  social: {},
  storage: {
    id: "",
  },
  theme: {
    id: "",
    defaultView: "masonry",
    bannerGradient: false,

    primaryMain,
    primaryLight,
    primaryDark,
    primaryContrastText,

    secondaryMain,
    secondaryLight,
    secondaryDark,
    secondaryContrastText,
    type,

    successMain: success,
    warningMain: warning,
    errorMain: error,
    infoMain: info,

    textPrimary,
    textSecondary,
    textDisabled,

    backgroundDefault,
    backgroundPaper,

    divider,
    footer,
  },
};
