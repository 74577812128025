import {
  AuthState,
  BannerState,
  ChartState,
  ContactState,
  CredentialState,
  DashboardState,
  DownloadHistoryState,
  FeaturedGroupState,
  FeaturedInvitationState,
  FeaturedThreadItemState,
  FeaturedThreadReleaseState,
  FeaturedThreadState,
  HubPluginState,
  ImageState,
  InitializeAuthState,
  InitializeBannerState,
  InitializeChartState,
  InitializeContactState,
  InitializeCredentialState,
  InitializeDashboardState,
  InitializeDownloadHistoryState,
  InitializeFeaturedInvitationState,
  InitializeFeaturedThreadItemState,
  InitializeFeaturedThreadReleaseState,
  InitializeFeaturedThreadState,
  InitializeGeneralFeaturedGroupState,
  InitializeHubPluginState,
  InitializeImageState,
  InitializeMainPaginationState,
  InitializeModalState,
  InitializePipelineFlowState,
  InitializePipelineGroupState,
  InitializePipelineJobState,
  InitializePipelineState,
  InitializeProfileState,
  InitializeRepositoryHistoryState,
  InitializeRepositoryItemState,
  InitializeRepositoryState,
  InitializeSelfFeaturedGroupState,
  InitializeSettingsState,
  InitializeSubscribedFeaturedGroupState,
  InitializeTileState,
  InitializeTrendingState,
  InitializeUiState,
  InitializeUpstreamFeaturedThreadState,
  InitializeUpstreamPipelineState,
  InitializeUserState,
  MainPaginationState,
  ModalState,
  PipelineFlowState,
  PipelineGroupState,
  PipelineJobState,
  PipelineState,
  ProfileState,
  RepositoryHistoryState,
  RepositoryItemState,
  RepositoryState,
  SettingsState,
  TileState,
  TrendingState,
  UiState,
  UpstreamFeaturedThreadState,
  UpstreamPipelineState,
  UserState,
} from "./interface";
import { createStore } from "@datorama/akita";

export const SESSION_STORE = "session_store";

export interface SessionState {
  auth: AuthState;
  banners: BannerState;
  charts: ChartState;
  contacts: ContactState;
  downloadHistory: DownloadHistoryState;
  modal: ModalState;
  mainPagination: MainPaginationState;
  profile: ProfileState;
  images: ImageState;
  repositories: RepositoryState;
  repositoryHistory: RepositoryHistoryState;
  repositoryItems: RepositoryItemState;
  credentials: CredentialState;
  settings: SettingsState;
  ui: UiState;
  dashboard: DashboardState;
  pipelines: PipelineState;
  pipelineJobs: PipelineJobState;
  pipelineFlows: PipelineFlowState;
  pipelineGroups: PipelineGroupState;
  selfFeaturedGroups: FeaturedGroupState;
  generalFeaturedGroups: FeaturedGroupState;
  subscribedFeaturedGroups: FeaturedGroupState;
  featuredThreads: FeaturedThreadState;
  featuredThreadReleases: FeaturedThreadReleaseState;
  featuredThreadItems: FeaturedThreadItemState;
  featuredInvitations: FeaturedInvitationState;
  tiles: TileState;
  trending: TrendingState;
  users: UserState;
  upstreamFeaturedThreads: UpstreamFeaturedThreadState;
  upstreamPipelines: UpstreamPipelineState;
  hubPlugins: HubPluginState;
}

export function createInitialState(): SessionState {
  return {
    auth: InitializeAuthState(),
    banners: InitializeBannerState(),
    charts: InitializeChartState(),
    ui: InitializeUiState(),
    dashboard: InitializeDashboardState(),
    mainPagination: InitializeMainPaginationState(),
    profile: InitializeProfileState(),
    settings: InitializeSettingsState(),
    images: InitializeImageState(),
    repositories: InitializeRepositoryState(),
    repositoryHistory: InitializeRepositoryHistoryState(),
    repositoryItems: InitializeRepositoryItemState(),
    credentials: InitializeCredentialState(),
    contacts: InitializeContactState(),
    downloadHistory: InitializeDownloadHistoryState(),
    modal: InitializeModalState(),
    pipelines: InitializePipelineState(),
    pipelineJobs: InitializePipelineJobState(),
    pipelineFlows: InitializePipelineFlowState(),
    pipelineGroups: InitializePipelineGroupState(),
    selfFeaturedGroups: InitializeSelfFeaturedGroupState(),
    subscribedFeaturedGroups: InitializeSubscribedFeaturedGroupState(),
    generalFeaturedGroups: InitializeGeneralFeaturedGroupState(),
    featuredThreads: InitializeFeaturedThreadState(),
    featuredThreadReleases: InitializeFeaturedThreadReleaseState(),
    featuredThreadItems: InitializeFeaturedThreadItemState(),
    featuredInvitations: InitializeFeaturedInvitationState(),
    tiles: InitializeTileState(),
    trending: InitializeTrendingState(),
    users: InitializeUserState(),
    upstreamFeaturedThreads: InitializeUpstreamFeaturedThreadState(),
    upstreamPipelines: InitializeUpstreamPipelineState(),
    hubPlugins: InitializeHubPluginState(),
  };
}

export const sessionStore = createStore<SessionState>(createInitialState(), {
  name: SESSION_STORE,
});
