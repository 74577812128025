export const LOGIN = "login";
export const REFRESH = "refresh";
export const VERIFY = "verify";
export const REDIRECT = "redirect";
export const REDIRECT_MOBILE = "redirect-mobile";
export const CHECK_USER = "check-user";
export const GET_AUTH_URL = "get-auth-url";
export const INITIATE_CHANGE_DOMAIN = "initiate-change-domain";
export const CHANGE_DOMAIN = "change-domain";

export const AUTH = "auth";
export const AUTH_SCHEME = "auth-scheme";
export const AUTH_SCHEME_OPEN = "open";
export const AUTH_SCHEME_REQUEST = "request";
export const AUTH_SCHEME_INVITE = "invite";
export const AUTH_SCHEME_PRIVATE = "private";

export const FORGOT_PASSWORD_ACTION = "forgot-password";
export const RESET_PASSWORD_ACTION = "reset-password";

export const APPLE_AUTH = "apple-auth";
export const GOOGLE_AUTH = "google-auth";
