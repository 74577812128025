import { includes, intersection, isArray } from "lodash-es";
import { useObservable } from "react-use";
import React, { useMemo } from "react";

import { Redirect } from "react-router-dom";

import { auth$ } from "state/session/query";

import * as roleConstants from "constants/roles";

const permissionsChecker = (
  arr: string | any[],
  items: any[],
  matchAny?: boolean,
) => {
  if (matchAny) {
    return intersection(arr, items).length > 0;
  }
  return items.every((v: any) => arr.includes(v));
};

interface CheckPermissionsProps {
  permissions: string[];
  matchAny?: boolean;
  redirectTo?: string;
  children?: any;
}

export default function CheckPermissions(config: CheckPermissionsProps) {
  const auth = useObservable(auth$);
  const { permissions, matchAny, redirectTo, children } = config;
  const hasRequiredPermissions: boolean = useMemo(() => {
    if (!isArray(permissions)) {
      throw new Error(
        "CheckPermissions: permissions input must be of array type.",
      );
    }

    if (!permissions.length && !auth) {
      // Return true if permissions are empty and auth is also empty
      return true;
    }

    if (!auth) {
      return false;
    }

    if (auth.permissions.length && !permissions.length) {
      // Return false if permissions are empty but auth.permissions are not
      return false;
    }

    if (includes(auth.permissions, roleConstants.PERMISSIONS_HUB_SUPER)) {
      return true;
    } else {
      return permissionsChecker(auth.permissions, permissions, matchAny);
    }
  }, [auth, auth?.permissions, permissions, matchAny]);

  return hasRequiredPermissions ? (
    <>{children}</>
  ) : (
    <>{redirectTo && auth && <Redirect to={redirectTo} />}</>
  );
}
