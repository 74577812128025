export const USERS = "users";
export const DEVICES = "devices";

export const WITH_SITE = "with-site";
export const CHECK_REGISTRATION = "check-registration";
export const REGISTER_DEVICE = "register-device";
export const SET_TIMEZONE = "set-tz";
export const STANDALONE = "standalone";

export const PROFILE = "profile";
export const PROFILES = "profiles";
