import {
  faFacebookSquare,
  faGithubSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitter,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import * as roleConstants from "constants/roles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    padding: 30,
    background: "#f3f3f3",
  },

  logoContainer: {
    marginBottom: 30,

    "& img": {
      maxWidth: 75,
      maxHeight: 75,
    },
  },

  textCenter: {
    textAlign: "center",
  },
}));

const year = new Date().getFullYear();

interface FooterParams {
  hideLogo?: boolean;
}

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Typography variant={"subtitle1"} gutterBottom>
        Copyright © {year} {"Wordparrot Inc."}. All rights reserved.
      </Typography>
      <div>
        <Link href={"https://hub.wordparrot.com"} color="primary">
          <Typography variant={"subtitle2"}>
            https://hub.wordparrot.com
          </Typography>
        </Link>
      </div>
    </footer>
  );
}
