import * as yup from "yup";
import { useEpic } from "use-epic";
import { useHistory } from "react-router-dom";
import { useObservable } from "react-use";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { Form, Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import GoogleButton from "react-google-button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  forgotPasswordEpic,
  googleLoginEpic,
  loginEpic,
  resetPasswordEpic,
} from "./epic";
import { site$ } from "state/site/query";

import FormikTextField from "modules/shared/Forms/TextField";
import TabPanel from "modules/shared/Atoms/TabPanel";

import { AppConfig } from "config";

import SaveLoginInfo from "modules/login/Atoms/SaveLoginInfo";

import * as loginConstants from "constants/login";
import * as routingConstants from "lib/routing";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#5EB3FF",
    backgroundImage: "linear-gradient(0deg, #5EB3FF 0%, #aee5f4 74%)",
  },

  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    margin: 0,
    padding: 10,
  },

  card: {
    position: "relative",
    zIndex: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    minHeight: 350,

    [theme.breakpoints.down("xl")]: {
      padding: 0,
    },

    [theme.breakpoints.up("lg")]: {
      minWidth: 350,
      padding: 30,
    },
  },

  cardContent: {
    width: "100%",
  },

  avatarContainer: {
    marginBottom: 10,
    textAlign: "center",
  },

  avatar: {
    margin: "auto",
  },

  submit: {
    marginTop: 15,
    textAlign: "center",
  },

  link: {
    cursor: "pointer",
  },

  links: {
    fontWeight: 600,
    textAlign: "center",
  },

  alert: {
    margin: "20px 0px",
  },

  socialLogin: {
    padding: "30px 0px",
    textAlign: "center",
  },

  socialLoginInner: {
    padding: "15px 0px",
    textAlign: "center",
  },

  appleButton: {
    width: 240,
    paddingTop: 9,
    paddingBottom: 9,
    color: "#000000",
    fontSize: 16,
    textTransform: "none",
    background: "#ffffff",
    border: "1px solid #000000",
    borderRadius: 0,

    "&:hover": {
      color: "#000000",
      background: "#ffffff",
    },

    "& img": {
      width: 25,
      marginRight: 10,
    },
  },

  googleButton: {
    margin: "auto",
  },

  or: {
    marginBottom: 10,
  },

  marginAuto: {
    margin: "auto",
  },
}));

const loginInitial = {
  email: "",
  password: "",
};
const loginSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});
const forgotInitial = {
  email: "",
};
const forgotSchema = yup.object().shape({
  email: yup.string().required("Required"),
});
const resetInitial = {
  key: "",
  password: "",
  confirm: "",
};
const resetSchema = yup.object().shape({
  key: yup.string().required(),
  password: yup.string().required("Required"),
  confirm: yup.string().required("Required"),
});

const renderStatus = (status: string | null, classes) => {
  switch (status) {
    case loginConstants.STATUS_LOCKED:
      return (
        <div className={classes.alert}>
          <Alert severity="warning">
            <AlertTitle>Warning: Site Locked</AlertTitle>
            This site has been locked by Wordparrot.
            <br></br>
            Please get in touch with us to learn more.
          </Alert>
        </div>
      );
    case loginConstants.STATUS_BANNED:
      return (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>Warning: Site Banned</AlertTitle>
            This site has been banned for violation of Wordparrot Terms of
            Service (TOS).
            <br></br>
            Please contact us if you believe there is an error.
          </Alert>
        </div>
      );
    case loginConstants.STATUS_POST_REGISTER:
      return (
        <div className={classes.alert}>
          <Alert severity="info">
            <AlertTitle>New Site: Verify Email</AlertTitle>A verification
            message has been sent to your email.
            <br></br>
            Once completed, you can log into your site dashboard.
          </Alert>
        </div>
      );
    default:
      return <></>;
  }
};

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const [index, setIndex] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const site = useObservable(site$);
  const status = useMemo(() => {
    return new URLSearchParams(document.location.search.substring(1)).get(
      "status",
    );
  }, []);

  const [_, dispatch] = useEpic(loginEpic, {});
  const [__, dispatchForgot] = useEpic(forgotPasswordEpic);
  const [___, dispatchReset] = useEpic(resetPasswordEpic);
  const [_____, dispatchGoogle] = useEpic(googleLoginEpic);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  const handleChange = useCallback(
    (number) => () => {
      setIndex(number);
    },
    [setIndex],
  );

  const submitHandler: any = useCallback(
    (values) => {
      dispatch({
        history,
        values,
        saveInfo,
      });
    },
    [dispatch, saveInfo],
  );
  const submitForgotHandler: any = useCallback(
    (values, actions) => {
      dispatchForgot({
        values,
        setIndex,
      });
      setEmail(values.email);
    },
    [dispatchForgot],
  );
  const submitResetHandler: any = useCallback(
    (values, actions) => {
      dispatchReset({
        values: { ...values, email },
        setIndex,
      });
    },
    [dispatchReset, email],
  );

  const handleGoogleLogin = useCallback(() => {
    dispatchGoogle({ history });
  }, [dispatchGoogle, history]);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.container}
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Avatar
                  alt="Wordparrot"
                  src="/img/parrot_og.png"
                  className={classes.avatar}
                />
              </div>
              <div>
                <Typography color="primary" variant="h6" align="center">
                  {site?.title}
                </Typography>
              </div>
              {renderStatus(status, classes)}
              <TabPanel name={""} value={0} index={index}>
                <Formik
                  initialValues={loginInitial}
                  validationSchema={loginSchema}
                  onSubmit={submitHandler}
                >
                  {({ values, setFieldValue, errors }: any) => (
                    <Form>
                      <Grid container>
                        <Grid
                          xs={12}
                          md={9}
                          xl={6}
                          className={classes.marginAuto}
                        >
                          <FormGroup>
                            <FormikTextField
                              name="email"
                              label="Email"
                              type="text"
                              helperText="Required"
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormikTextField
                              style={{ width: "100%" }}
                              name="password"
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              helperText="Required"
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    size="small"
                                    onClick={handleShowPassword}
                                  >
                                    {!showPassword && <VisibilityIcon />}
                                    {showPassword && <VisibilityOffIcon />}
                                  </IconButton>
                                ),
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <SaveLoginInfo
                              label={"Save my info for next time"}
                              value={saveInfo}
                              handler={setSaveInfo}
                            />
                          </FormGroup>
                          <FormGroup className={classes.submit}>
                            <Button variant="contained" type="submit">
                              Login
                            </Button>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <div className={classes.socialLogin}>
                        <Divider></Divider>
                        <div className={classes.socialLoginInner}>
                          <div className={classes.or}>- or -</div>
                          <div>
                            <GoogleButton
                              onClick={handleGoogleLogin}
                              className={classes.googleButton}
                            />
                          </div>
                        </div>
                        <Divider></Divider>
                      </div>
                      <Grid container className={clsx(classes.links)}>
                        <Grid item xs={12}>
                          <Link
                            className={clsx(classes.link, classes.or)}
                            onClick={handleChange(1)}
                          >
                            Forgot your password?
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <RouterLink to={"/signup"}>
                            Create an account
                          </RouterLink>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel name={""} value={1} index={index}>
                <Formik
                  initialValues={forgotInitial}
                  validationSchema={forgotSchema}
                  onSubmit={submitForgotHandler}
                >
                  {({ values, setFieldValue, errors }: any) => (
                    <Form>
                      <FormGroup>
                        <FormikTextField
                          name="email"
                          label="Email"
                          type="text"
                          helperText="Required"
                        />
                      </FormGroup>
                      <FormGroup className={classes.submit}>
                        <Button variant="contained" type="submit">
                          Reset Password
                        </Button>
                      </FormGroup>
                      <FormGroup className={classes.submit}>
                        <Link
                          className={classes.link}
                          onClick={handleChange(0)}
                        >
                          Back
                        </Link>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel name={""} value={2} index={index}>
                <Formik
                  initialValues={resetInitial}
                  validationSchema={resetSchema}
                  onSubmit={submitResetHandler}
                >
                  {({ values, setFieldValue, errors }: any) => (
                    <Form>
                      <FormGroup>
                        <FormikTextField
                          style={{ width: "100%" }}
                          name="key"
                          label="Reset Key"
                          type="text"
                          helperText="Required"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormikTextField
                          style={{ width: "100%" }}
                          name="password"
                          label="Password"
                          type="password"
                          helperText="Required"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormikTextField
                          style={{ width: "100%" }}
                          name="confirm"
                          label="Confirm"
                          type="password"
                          helperText="Required"
                        />
                      </FormGroup>
                      <FormGroup className={classes.submit}>
                        <Button variant="contained" type="submit">
                          Create New Password
                        </Button>
                      </FormGroup>
                      <FormGroup className={classes.submit}>
                        <Link
                          className={classes.link}
                          onClick={handleChange(0)}
                        >
                          Back
                        </Link>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
