import { FieldMetaProps, useField } from "formik";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { AppConfig } from "config";

const useStyles = makeStyles((theme) => ({
  label: {
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));

const SaveLoginInfo = ({ value, handler, label }) => {
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();

  useEffect(() => {
    void (async () => {
      let values;
      values = window.localStorage.getItem(AppConfig.localStorageLoginInfo);

      try {
        values = JSON.parse(values);
        setFieldValue("email", values.email);
        setFieldValue("password", values.password);
        handler(true);
      } catch (e) {
        // nothing found
      }
    })();
  }, [handler, setFieldValue]);

  const handleChange = useCallback(() => {
    handler(!value);
  }, [value, handler]);

  return (
    <FormControlLabel
      className={classes.label}
      control={
        <Checkbox checked={value} onChange={handleChange} value={value} />
      }
      label={label}
    />
  );
};

export default SaveLoginInfo;
