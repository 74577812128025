export const CREATE = "create";
export const UPDATE = "update";
export const LIST = "list";
export const ITEMS = "items";
export const DISPLAY = "display";
export const REDIRECT = "redirect";
export const REDIRECT_MOBILE = "redirect_mobile";
export const SUBSCRIBED = "subscribed";
export const THREADS = "threads";
export const LATEST = "latest";
export const FEATURED = "featured";
export const SELF = "self";
export const RELEASES = "releases";
export const INFO = "info";
export const CHARTS = "charts";
export const CREDENTIALS = "credentials";
export const SITES = "sites";
export const SEARCH = "search";
export const PAGE = "page";
export const TRASH = "trash";
export const GROUPS = "groups";
export const STATUS = "status";
export const TRENDING = "trending";
export const VERSIONS = "versions";
export const UPLOAD = "upload";

export const ROUTE_MAIN = `/`;
export const ROUTE_MAIN_PAGE_CATEGORY = `/${PAGE}/:categorySlug`;
export const ROUTE_MAIN_PAGE_SUBCATEGORY = `/${PAGE}/:categorySlug/:subcategorySlug`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_LOGOUT = `/logout`;
export const ROUTE_SIGNUP = `/signup`;
export const ROUTE_NOT_FOUND = `/not-found`;

export const ROUTE_SITES = `/${SITES}`;
export const ROUTE_SITES_MAIN = `/${SITES}/main`;
export const ROUTE_SITES_REGISTER = `/${SITES}/register`;
export const ROUTE_SITES_REGISTER_SUCCESS = `/${SITES}/register-success`;
export const ROUTE_SITES_DISPLAY_MOBILE = `/${SITES}/${DISPLAY}/:siteId`;
export const ROUTE_SITES_DISPLAY_MOBILE_CATEGORY = `${ROUTE_SITES_DISPLAY_MOBILE}/${PAGE}/:categorySlug`;
export const ROUTE_SITES_DISPLAY_MOBILE_SUBCATEGORY = `${ROUTE_SITES_DISPLAY_MOBILE_CATEGORY}/:subcategorySlug`;

export const ROUTE_DASHBOARD = `/dashboard`;
export const ROUTE_DASHBOARD_DOMAINS = `${ROUTE_DASHBOARD}/domains`;

export const ROUTE_DASHBOARD_MAIN = `${ROUTE_DASHBOARD}/main`;
export const ROUTE_DASHBOARD_MAIN_MOBILE = `${ROUTE_DASHBOARD_MAIN}/mobile`;

export const ROUTE_DASHBOARD_TILE_LIST = `${ROUTE_DASHBOARD}/tiles/${LIST}`;
export const ROUTE_DASHBOARD_TILE_CREATE = `${ROUTE_DASHBOARD}/tiles/${CREATE}`;
export const ROUTE_DASHBOARD_TILE_UPDATE = `${ROUTE_DASHBOARD}/tiles/:id/${UPDATE}`;

export const ROUTE_DASHBOARD_DNS = `${ROUTE_DASHBOARD}/dns`;
export const ROUTE_DASHBOARD_GENERAL = `${ROUTE_DASHBOARD}/general`;
export const ROUTE_DASHBOARD_CATEGORIES = `${ROUTE_DASHBOARD}/categories`;
export const ROUTE_DASHBOARD_CATEGORIES_META = `${ROUTE_DASHBOARD_CATEGORIES}/meta`;
export const ROUTE_DASHBOARD_CATEGORIES_META_UPDATE = `${ROUTE_DASHBOARD_CATEGORIES_META}/:categoryMetaId`;
export const ROUTE_DASHBOARD_THEME = `${ROUTE_DASHBOARD}/theme`;
export const ROUTE_DASHBOARD_SEO = `${ROUTE_DASHBOARD}/seo`;
export const ROUTE_DASHBOARD_SOCIAL = `${ROUTE_DASHBOARD}/social`;
export const ROUTE_DASHBOARD_STORAGE = `${ROUTE_DASHBOARD}/storage`;
export const ROUTE_DASHBOARD_BANNERS = `${ROUTE_DASHBOARD}/banners`;
export const ROUTE_DASHBOARD_SETTINGS = `${ROUTE_DASHBOARD}/settings`;
export const ROUTE_DASHBOARD_PROFILE = `${ROUTE_DASHBOARD}/profile`;
export const ROUTE_DASHBOARD_SITE_SUBSCRIPTIONS = `${ROUTE_DASHBOARD}/${SITES}/subscriptions`;
export const ROUTE_DASHBOARD_SITE_SUBSCRIPTIONS_SEARCH_MOBILE = `${ROUTE_DASHBOARD}/${SITES}/subscriptions/${SEARCH}`;

export const ROUTE_DIRECTORY_PREFIX = `${ROUTE_DASHBOARD}/directory`;
export const ROUTE_DIRECTORY_MAIN = `${ROUTE_DIRECTORY_PREFIX}/main`;
export const ROUTE_DIRECTORY_TREE = `${ROUTE_DIRECTORY_PREFIX}/tree`;
export const ROUTE_DIRECTORY_ANALYTICS = `${ROUTE_DIRECTORY_PREFIX}/analytics`;

// Media
export const ROUTE_MEDIA_PREFIX = `${ROUTE_DASHBOARD}/media`;
export const ROUTE_MEDIA_IMAGES = `${ROUTE_MEDIA_PREFIX}/images`;
export const ROUTE_MEDIA_BANNERS = `${ROUTE_MEDIA_PREFIX}/banners`;

// Blueprints
export const ROUTE_BLUEPRINTS_PREFIX = `/blueprints`;
export const ROUTE_BLUEPRINTS_UPDATE = `${ROUTE_BLUEPRINTS_PREFIX}/:blueprintMetaId`;
export const ROUTE_BLUEPRINTS_DISPLAY = `${ROUTE_BLUEPRINTS_UPDATE}/${DISPLAY}`;
export const ROUTE_BLUEPRINTS_CREATE = `${ROUTE_BLUEPRINTS_PREFIX}/${CREATE}`;
export const ROUTE_BLUEPRINT_VERSIONS_PREFIX = `${ROUTE_BLUEPRINTS_UPDATE}/${VERSIONS}`;
export const ROUTE_BLUEPRINT_VERSIONS_CREATE = `${ROUTE_BLUEPRINT_VERSIONS_PREFIX}/${CREATE}`;
export const ROUTE_BLUEPRINT_VERSIONS_UPDATE = `${ROUTE_BLUEPRINT_VERSIONS_PREFIX}/:blueprintVersionId`;
export const ROUTE_BLUEPRINT_VERSIONS_DISPLAY = `${ROUTE_BLUEPRINT_VERSIONS_PREFIX}/:blueprintVersionId/${DISPLAY}`;
export const ROUTE_BLUEPRINT_VERSIONS_UPLOAD = `${ROUTE_BLUEPRINT_VERSIONS_PREFIX}/:blueprintVersionId/${UPLOAD}`;

// Pipelines
export const ROUTE_PIPELINES_PREFIX = `${ROUTE_DASHBOARD}/pipelines`;
export const ROUTE_PIPELINES_LIST = `${ROUTE_PIPELINES_PREFIX}/${LIST}`;
export const ROUTE_PIPELINES_LIST_STATUS = `${ROUTE_PIPELINES_PREFIX}/${LIST}/:${STATUS}`;
export const ROUTE_PIPELINES_CREATE = `${ROUTE_PIPELINES_PREFIX}/${CREATE}`;
export const ROUTE_PIPELINES_UPDATE = `${ROUTE_PIPELINES_PREFIX}/:pipelineId`;

export const ROUTE_PIPELINE_GROUPS_PREFIX = `${ROUTE_PIPELINES_PREFIX}/${GROUPS}`;
export const ROUTE_PIPELINE_GROUPS_LIST = `${ROUTE_PIPELINE_GROUPS_PREFIX}/${LIST}`;
export const ROUTE_PIPELINE_GROUPS_CREATE = `${ROUTE_PIPELINE_GROUPS_PREFIX}/${CREATE}`;
export const ROUTE_PIPELINE_GROUPS_UPDATE = `${ROUTE_PIPELINE_GROUPS_PREFIX}/:pipelineGroupId`;

export const ROUTE_PIPELINE_NODES_ROOT = `nodes`;
export const ROUTE_PIPELINE_NODES_CREATION_PATH = `${ROUTE_PIPELINE_NODES_ROOT}/create`;
export const ROUTE_PIPELINE_NODES_CREATE = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_NODES_CREATION_PATH}`;
export const ROUTE_PIPELINE_NODES_UPDATE = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_NODES_ROOT}/:pipelineNodeId`;
export const ROUTE_PIPELINE_NODES_ADD_CHILD_ROOT = `add-child`;
export const ROUTE_PIPELINE_NODES_ADD_CHILD = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_NODES_ROOT}/:pipelineNodeId/${ROUTE_PIPELINE_NODES_ADD_CHILD_ROOT}`;
export const ROUTE_PIPELINE_JOBS_ROOT = `jobs`;
export const ROUTE_PIPELINE_JOBS_LIST = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_JOBS_ROOT}`;
export const ROUTE_PIPELINE_JOBS_PAGE = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_JOBS_ROOT}/:pipelineJobId`;

// Repository
export const ROUTE_REPOSITORY_PREFIX = `${ROUTE_DASHBOARD}/repositories`;
export const ROUTE_REPOSITORY_LIST = `${ROUTE_REPOSITORY_PREFIX}/${LIST}`;
export const ROUTE_REPOSITORY_CREATE = `${ROUTE_REPOSITORY_PREFIX}/${CREATE}`;
export const ROUTE_REPOSITORY_UPDATE = `${ROUTE_REPOSITORY_PREFIX}/:repositoryId`;
export const ROUTE_REPOSITORY_DISPLAY = `${ROUTE_REPOSITORY_UPDATE}/${DISPLAY}`;
export const ROUTE_REPOSITORY_ITEMS = `${ROUTE_REPOSITORY_UPDATE}/${ITEMS}`;
export const ROUTE_REPOSITORY_CHARTS = `${ROUTE_REPOSITORY_UPDATE}/${CHARTS}`;
export const ROUTE_REPOSITORY_CHART_CREATE = `${ROUTE_REPOSITORY_UPDATE}/${CHARTS}/${CREATE}`;
export const ROUTE_REPOSITORY_CHART_UPDATE = `${ROUTE_REPOSITORY_UPDATE}/${CHARTS}/:chartId`;
export const ROUTE_REPOSITORY_ITEM = `${ROUTE_REPOSITORY_ITEMS}/:repositoryItemId`;

// User
export const ROUTE_USER_PREFIX = `${ROUTE_DASHBOARD}/users`;
export const ROUTE_USER_LIST = `${ROUTE_USER_PREFIX}/${LIST}`;
export const ROUTE_USER_CREATE = `${ROUTE_USER_PREFIX}/${CREATE}`;

// Storage
export const ROUTE_STORAGE_PREFIX = `${ROUTE_DASHBOARD}/store`;
export const ROUTE_STORAGE_LIST = `${ROUTE_STORAGE_PREFIX}/${LIST}`;
export const ROUTE_STORAGE_CREDENTIALS_CREATE = `${ROUTE_STORAGE_PREFIX}/${CREDENTIALS}/${CREATE}`;

// Entry
export const ROUTE_ENTRY_PREFIX = `${ROUTE_DASHBOARD}/entries`;
export const ROUTE_ENTRY_LIST = `${ROUTE_ENTRY_PREFIX}/${LIST}`;
export const ROUTE_ENTRY_TRASH = `${ROUTE_ENTRY_PREFIX}/${TRASH}`;
export const ROUTE_ENTRY_TRENDING = `${ROUTE_ENTRY_PREFIX}/${TRENDING}`;
export const ROUTE_ENTRY_CREATE = `${ROUTE_ENTRY_PREFIX}/${CREATE}`;
export const ROUTE_ENTRY_UPDATE = `${ROUTE_ENTRY_PREFIX}/:entryId`;
export const ROUTE_ENTRY_PREVIEW_SUFFIX = `preview`;
export const ROUTE_ENTRY_PREVIEW = `${ROUTE_ENTRY_UPDATE}/${ROUTE_ENTRY_PREVIEW_SUFFIX}`;

// Featured Group
export const ROUTE_FEATURED_GROUP_PREFIX = `/${FEATURED}`;
export const ROUTE_FEATURED_GROUP_LIST_PREFIX = `${ROUTE_FEATURED_GROUP_PREFIX}/${LIST}`;

// Featured Group List Pages
export const ROUTE_FEATURED_GROUP_LIST_SELF = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${SELF}`;
export const ROUTE_FEATURED_GROUP_LIST_LATEST = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${LATEST}`;
export const ROUTE_FEATURED_GROUP_LIST_SUBSCRIBED = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${SUBSCRIBED}`;
export const ROUTE_FEATURED_GROUP_LIST_RELEASES = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${RELEASES}`;

// Individual pages for one group
export const ROUTE_FEATURED_GROUP_SUBSCRIPTION_INFO = `${ROUTE_FEATURED_GROUP_PREFIX}/:featuredGroupId/${INFO}`;

export const ROUTE_FEATURED_GROUP_CREATE = `${ROUTE_FEATURED_GROUP_PREFIX}/${CREATE}`;
export const ROUTE_FEATURED_GROUP_UPDATE = `${ROUTE_FEATURED_GROUP_PREFIX}/:featuredGroupId`;
export const ROUTE_FEATURED_GROUP_DISPLAY = `${ROUTE_FEATURED_GROUP_PREFIX}/:featuredGroupId/${DISPLAY}`;

// Threads
export const ROUTE_FEATURED_GROUP_THREAD_CREATE = `${ROUTE_FEATURED_GROUP_UPDATE}/${THREADS}/${CREATE}`;
export const ROUTE_FEATURED_GROUP_THREAD_UPDATE = `${ROUTE_FEATURED_GROUP_UPDATE}/${THREADS}/:featuredThreadId`;

// Thread Charts
export const ROUTE_FEATURED_THREAD_CHARTS = `${ROUTE_FEATURED_GROUP_THREAD_UPDATE}/${CHARTS}`;
export const ROUTE_FEATURED_THREAD_CHART_CREATE = `${ROUTE_FEATURED_THREAD_CHARTS}/${CREATE}`;
export const ROUTE_FEATURED_THREAD_CHART_UPDATE = `${ROUTE_FEATURED_THREAD_CHARTS}/:chartId`;

// Releases
export const ROUTE_FEATURED_THREAD_RELEASES = `${ROUTE_FEATURED_GROUP_THREAD_UPDATE}/${RELEASES}`;

export const ROUTE_FEATURED_THREAD_RELEASE = `${ROUTE_FEATURED_THREAD_RELEASES}/:featuredThreadReleaseId`;

// Items
export const ROUTE_FEATURED_THREAD_RELEASE_ITEMS = `${ROUTE_FEATURED_THREAD_RELEASE}/${ITEMS}`;
export const ROUTE_FEATURED_THREAD_RELEASE_ITEM_DETAILED = `${ROUTE_FEATURED_THREAD_RELEASE}/${ITEMS}/:featuredItemId`;

// Plugins
export const ROUTE_PLUGINS_PREFIX = `/plugins`;
export const ROUTE_PLUGINS_LIST = `${ROUTE_PLUGINS_PREFIX}/${LIST}`;
export const ROUTE_PLUGINS_MAIN = `${ROUTE_PLUGINS_PREFIX}/:hubPluginId`;

// Profiles
export const ROUTE_PROFILES_PREFIX = `/profiles`;
export const ROUTE_PROFILES_LIST = `${ROUTE_PROFILES_PREFIX}/${LIST}`;
export const ROUTE_PROFILES_MAIN = `${ROUTE_PROFILES_PREFIX}/:profileId`;
