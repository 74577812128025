import { FieldMetaProps, useField } from "formik";
import React from "react";
import isString from "lodash-es/isString";
import makeStyles from "@mui/styles/makeStyles";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "100px",
    maxHeight: "150px",
    marginTop: "15px",
    padding: "10px 2px",
  },

  textareaError: {
    border: `2px solid ${theme.palette.error.main}`,
  },
}));

const renderHelperText = (errorStatus, input) => {
  if (!errorStatus) {
    return null;
  }
  if (isString(input)) {
    return <FormHelperText>{input}</FormHelperText>;
  }
  return <FormHelperText component={input} />;
};

export default function FormikTextField({ label, name, helperText, ...props }) {
  const classes = useStyles();
  const [field, meta, helpers] = useField({
    ...props,
    name,
  });
  const {
    error,
    touched,
  }: FieldMetaProps<{ error: string; touched: boolean }> = meta;

  const errorStatus: boolean = (error && touched) === true;

  if (props.textarea) {
    return (
      <TextareaAutosize
        {...field}
        {...props}
        className={
          errorStatus
            ? clsx(classes.textarea, classes.textareaError)
            : classes.textarea
        }
      />
    );
  }

  return (
    <FormControl>
      <TextField {...field} {...props} label={label} error={errorStatus} />
      {renderHelperText(errorStatus, helperText)}
    </FormControl>
  );
}
