import { signupHost } from "theme/default";

import * as apiConstants from "constants/api";

export const AppConfig = {
  clientId: "com.wordparrot.automation",
  baseUrl: "https://www.wordparrot.com",
  baseDomain: "wordparrot.com",
  testDomain: "localhost:8080",
  accessUrl: `localhost:8080${apiConstants.API_ROOT}/access`,
  refreshUrl: `localhost:1815${apiConstants.API_ROOT}/refresh`,
  paymentSite: "http://localhost:3000",
  csrfHeader: "X-XSRF-TOKEN",
  refreshHeader: "X-REFRESH-TOKEN",
  siteAccessCookieName: "wordparrot-site-jwt",
  siteRefreshCookieName: "wordparrot-site-jwt-refresh",
  siteCsrfCookieName: "wordparrot-site-csrf",
  siteIdCookieName: "site-id",
  siteLogoCookieName: "site-logo",
  authSchemeCookieName: "auth-scheme",
  refreshTokenExpiration: "30d",
  accessTokenExpiration: "1h",

  // Android/iOS
  webviewIdentifier: "X-WORDPARROT-WEBVIEW",
  webviewIdentifierDefault: "default",

  localStorageLoginInfo: "wordparrot-login-email",

  adminHost: "admin.wordparrot.com",

  signupHost: signupHost,
  signupHostWithProtocol: `https://${signupHost}`,
  signupHostWithCapacitorProtocol: `capacitor://${signupHost}`,

  commentoHost: "https://commento.wordparrot.com",

  localeCookieName: "wordparrot-locale",
  currencyCookieName: "wordparrot-currency",
  countryCookieName: "wordparrot-country",
  facebookPage: "https://www.facebook.com",
  twitterPage: "https://www.twitter.com",
  linkedInPage: "https://www.linkedin.com",
  defaultImage: "/img/default_profile.png",
  // 55 Minutes
  uploaderConfig: {
    url: `${apiConstants.API_ROOT}/orders/`,
    csvUploaderOptions: {
      concurrency: 1,
      allowedContentTypes: [
        "application/pdf",
        "text/plain",
        "text/csv",
        "application/vnd.ms-excel",
      ],
    },
    uploaderOptions: {
      concurrency: 1,
      allowedContentTypes: [
        "text/csv",
        "plain",
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-word",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
    },
  },
  flags: {
    en: "us",
    "en-US": "us",
    "es-US": "us",
    usd: "us",
    es: "es",
    "en-CA": "ca",
    cad: "ca",
    "fr-CA": "ca",
    "en-GB": "gb",
    gbp: "gb",
    "zh-TW": "tw",
    twd: "tw",
    "zh-HK": "hk",
    hkd: "hk",
    "zh-CN": "cn",
    cny: "cn",
    zh: "cn",
    ko: "kr",
    ja: "jp",
    jpy: "jp",
    ms: "my",
    da: "dk",
    hi: "in",
    tl: "ph",
    fr: "fr",
    vi: "vn",
  },
  fileFormats: {
    ppt: "Microsoft PowerPoint (pre-2007)",
    pptx: "Microsoft PowerPoint",
    powerpoint: "Microsoft PowerPoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "Microsoft PowerPoint",
    "application/vnd.ms-powerpoint": "Microsoft PowerPoint",
    doc: "Microsoft Word (pre-2007)",
    docx: "Microsoft Word",
    "application/msword": "Microsoft Word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "Microsoft Word",
    excel: "Microsoft Excel",
    xlsx: "Microsoft Excel (xlsx)",
    "application/octet-stream": "Microsoft Excel",
    "text/csv": "Microsoft Excel/CSV",
    "text/xml": "XML",
    javascript: "Javascript",
    json: "JSON",
    "application/pdf": "PDF",
    pdf: "PDF",
    txt: "Text file",
    png: "png.jpg",
    imageTypes: [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
      "image/x-icon",
      "image/tiff",
    ],
  },
  fileImages: {
    ppt: "powerpoint.jpg",
    pptx: "powerpoint.jpg",
    powerpoint: "powerpoint.jpg",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "powerpoint.jpg",
    "application/vnd.ms-powerpoint": "powerpoint.jpg",

    doc: "word.jpg",
    docx: "word.jpg",
    "application/msword": "word.jpg",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "word.jpg",

    excel: "excel.png",
    xlsx: "excel.png",
    "application/octet-stream": "excel.png",
    "text/csv": "excel.png",
    "text/xml": "excel.png",

    javascript: "javascript.jpg",

    "application/pdf": "pdf.jpg",
    pdf: "pdf.png",

    png: "png.jpg",

    txt: "txt.jpg",
    "text/plain": "txt.jpg",
  },
  translatorQuestions: [
    "app.TRANSLATOR_QUESTION_ONE",
    "app.TRANSLATOR_QUESTION_TWO",
    "app.TRANSLATOR_QUESTION_THREE",
    "app.TRANSLATOR_QUESTION_FOUR",
  ],
  audio: {
    enterChat: "/audio/enter_chat.ogg",
    messageNotification: "/audio/message_notification.ogg",
    receiveMessage: "/audio/receive_message.ogg",
    sendMessage: "/audio/send_message.ogg",
    joinRoom: "/audio/join_room.ogg",
    leaveRoom: "/audio/leave_room.ogg",
  },

  paymentMethodTypes: ["card"],
};

export const ApiKeys = {};
