import { Observable, firstValueFrom, from, of } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { isNil, isUndefined } from "lodash-es";

import { ApiResponse, _delete, get, post, put } from "lib/api";
import { AppConfig } from "config";
import { sessionService } from "state/session/service";
import { snackbarService } from "services/Snackbar";

import * as apiConstants from "constants/api";
import * as authConstants from "constants/auth";
import * as loginConstants from "constants/login";
import * as roleConstants from "constants/roles";
import * as siteConstants from "constants/sites";
import * as userConstants from "constants/users";

export const setTimezone = (
  userId: string,
  timezone: string,
): Promise<ApiResponse<null>> => {
  return firstValueFrom(
    put<ApiResponse<null>>(
      `${apiConstants.API_HUB_ROOT}/${userConstants.USERS}/${userId}/${userConstants.SET_TIMEZONE}`,
      {
        body: {
          timezone,
        },
      },
    ),
  );
};

export const registerProfile = (authorizedProfile: AuthorizedProfile): void => {
  sessionService.updateProfile(authorizedProfile);
  sessionService.updateAuth({
    hasReceivedAuth: true,
    isLoggedIn: true,
    permissions: authorizedProfile.permissions,
  });
  if (
    authorizedProfile.timezone !==
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    // User has different or unset timezone, adjust for user.
    void (async () => {
      await setTimezone(
        authorizedProfile.id,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
    })();
  }
};

class AuthService {
  constructor() {
    void (async () => {
      try {
        setInterval(() => {
          this.refresh().subscribe();
        }, 3000000);
      } catch (e) {
        // Cannot refresh access token + csrf
      }
    })();
  }

  login(form: LoginForm, saveInfo = false): Observable<any> {
    return post<
      ApiResponse<{
        refreshToken: string;
        accessToken: string;
        csrfToken: string;
      }>
    >(
      `${apiConstants.API_HUB_ROOT}/${authConstants.AUTH}/${siteConstants.SITES}/${loginConstants.LOGIN}`,
      {
        body: form,
      },
    ).pipe(
      map((response) => response.data),
      tap(() => {
        if (saveInfo) {
          window.localStorage.setItem(
            AppConfig.localStorageLoginInfo,
            JSON.stringify(form),
          );
        } else {
          window.localStorage.removeItem(AppConfig.localStorageLoginInfo);
        }
      }),
    );
  }

  forgotPassword(form: ForgotPasswordForm): Observable<void> {
    return post<void>(
      `${apiConstants.API_HUB_ROOT}/${authConstants.AUTH}/${siteConstants.SITES}/${loginConstants.FORGOT_PASSWORD}`,
      {
        body: form,
      },
    );
  }

  resetPassword(form: ResetPasswordForm): Observable<void> {
    return post<void>(
      `${apiConstants.API_HUB_ROOT}/${authConstants.AUTH}/${siteConstants.SITES}/${loginConstants.RESET_PASSWORD}`,
      {
        body: form,
      },
    );
  }

  fetchAuth(): Observable<AuthorizedProfile> {
    let obs: Observable<any>;

    obs = get<
      ApiResponse<{
        tokens: {
          accessToken: string;
          csrfToken: string;
        };
        authorizedProfile: AuthorizedProfile;
      }>
    >(
      `${apiConstants.API_HUB_ROOT}/${authConstants.AUTH}/${siteConstants.SITES}/${authConstants.VERIFY}`,
    );

    return obs.pipe(
      switchMap(
        (
          response: ApiResponse<{
            tokens: {
              accessToken: string;
              csrfToken: string;
            };
            authorizedProfile: AuthorizedProfile;
          }>,
        ) => {
          if (!response.result) {
            throw new Error("Auth verification failed");
          }

          const responseData = response.data;

          return of(responseData.authorizedProfile);
        },
      ),
      tap(registerProfile),
    );
  }

  refresh() {
    let obs: Observable<any>;
    obs = get<
      ApiResponse<{
        tokens: {
          accessToken: string;
          csrfToken: string;
        };
      }>
    >(
      `${apiConstants.API_HUB_ROOT}/${authConstants.AUTH}/${siteConstants.SITES}/${authConstants.REFRESH}`,
    );

    return obs.pipe(
      switchMap(
        (
          response: ApiResponse<{
            tokens: {
              accessToken: string;
              csrfToken: string;
            };
          }>,
        ) => {
          if (!response.result) {
            throw new Error("Auth verification failed");
          }

          const responseData = response.data;

          return of(responseData);
        },
      ),
      catchError((e) => {
        // Swallow all errors here.
        return of({});
      }),
      // We only want the first value, so unsubscribe here.
      take(1),
    );
  }

  initiateChangeDomain(config: {
    domain: string;
  }): Observable<{ key: string }> {
    const { domain } = config;
    return get<ApiResponse<{ key: string }>>(
      `${apiConstants.API_HUB_ROOT}/${authConstants.AUTH}/${
        siteConstants.SITES
      }/${authConstants.INITIATE_CHANGE_DOMAIN}?domain=${encodeURIComponent(
        domain,
      )}`,
    ).pipe(map((response) => response.data));
  }
}

class GoogleLoginService {
  main(history) {
    return this.loginOnWeb();
  }

  loginOnWeb() {
    snackbarService.next({
      message: "Please wait a moment...",
      type: "loading",
      show: true,
    });

    return get<ApiResponse<{ authUrl: string }>>(
      `${apiConstants.API_HUB_ROOT}/${authConstants.GOOGLE_AUTH}/${authConstants.GET_AUTH_URL}`,
    ).pipe(
      map((response) => {
        const authUrl = response.data.authUrl;
        window.location.href = authUrl;
      }),
    );
  }
}

export const googleLoginService = new GoogleLoginService();

export interface SignupForm {
  email: string;
  password: string;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface ForgotPasswordForm {
  email: string;
}

export interface ResetPasswordForm {
  email: string;
  key: string;
  password: string;
  confirm: string;
}

export interface AuthorizedProfile {
  id: string;
  name: string;
  email: string;
  locale: string;
  timezone: string;
  roleIds: string[];
  permissions: string[];
  createdAt: string;
  updatedAt: string;
}

export const authService = new AuthService();
