import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PersonIcon from "@mui/icons-material/Person";
import PowerIcon from "@mui/icons-material/Power";
import Typography from "@mui/material/Typography";

import { largeNumber } from "lib/functions";

import * as routingConstants from "lib/routing";

const useStyles = makeStyles((theme) => ({
  banner: {
    height: 200,
    backgroundImage: `url('/img/hub.webp')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },

  gridContainer: {
    padding: "30px 0px",

    [theme.breakpoints.up("md")]: {
      padding: "30px",
    },
  },

  gridItem: {
    padding: 10,
  },

  card: {
    minHeight: 300,
    padding: 15,
    border: "1.5px solid #eeeeee",
    transition: ".3s",

    "&:hover": {
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
  },

  centered: {
    textAlign: "center",
  },

  icon: {
    margin: 15,
    fontSize: 70,
  },

  categories: {
    margin: "15px 0px",
  },

  list: {
    display: "flex",
  },

  listItem: {
    justifyContent: "center",
    margin: "0px 5px 5px 0px",
    padding: 3,
    fontWeight: 500,
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    cursor: "pointer",
    transition: ".3s",

    "&:hover": {
      background: "#f3f3f3",
    },
  },
}));

const getCards = (classes) => [
  {
    title: "Plugins",
    content: "Integrations for the Wordparrot core application.",
    icon: <PowerIcon className={classes.icon} />,
    url: routingConstants.ROUTE_PLUGINS_PREFIX,
    categories: [
      {
        title: "Productivity",
        url: "/stuff",
      },
      {
        title: "Sharing",
        url: "/stuff",
      },
      {
        title: "Datamining",
        url: "/stuff",
      },
    ],
    stats: [
      {
        figure: 12345678,
        title: "Plugins",
      },
      {
        figure: 5678,
        title: "This Month",
      },
    ],
  },
  {
    title: "Instances",
    content: "Check out current running instances, and spawn new ones.",
    icon: <PersonIcon className={classes.icon} />,
    url: "/admin/instances/list",
    categories: [],
    stats: [
      {
        figure: 548934,
        title: "Profiles",
      },
      {
        figure: 3949,
        title: "This month",
      },
    ],
  },
];

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const params: Record<string, string> = useParams();

  const cards = getCards(classes);

  const goToPage = useCallback(
    (url: string) => () => {
      history.push(url);
    },
    [history],
  );

  return (
    <Container>
      <Grid container className={classes.gridContainer}>
        {cards.map((card) => (
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.centered}>
                  <Typography variant="h5">{card.title}</Typography>
                  {card.icon}
                </div>
                <Typography variant="subtitle2">{card.content}</Typography>
                {card.categories && (
                  <div className={classes.categories}>
                    <List className={classes.list}>
                      {card.categories.map((category) => (
                        <ListItem className={classes.listItem}>
                          {category.title}
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
                {card.stats && (
                  <Grid container>
                    {card.stats.map((stat) => (
                      <Grid item xs={6} className={classes.centered}>
                        <Typography variant="h5">
                          {largeNumber(stat.figure)}
                        </Typography>
                        <Typography variant="subtitle2">
                          {stat.title}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}
                <div className={classes.centered}>
                  <br></br>
                  <Button onClick={goToPage(card.url)}>More</Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
