export const ROLES = "roles";

export const ROLE_SHAREABLE_PREFIX = "/s/";

export const ROLE_TYPE_DEFAULT = "default";
export const ROLE_TYPE_CUSTOM = "custom";
export const ROLE_TYPE_SHAREABLE = "shareable";

export const PERMISSIONS_HUB_SUPER = "hub.super";
export const PERMISSIONS_HUB_ADMIN = "hub.admin";
export const PERMISSIONS_HUB_MODERATOR = "hub.moderator";
export const PERMISSIONS_HUB_USER = "hub.user";

export const PERMISSIONS_SETTINGS = "site.settings";
export const PERMISSIONS_EDIT = "site.edit";
export const PERMISSIONS_PUBLISH = "site.publish";
export const PERMISSIONS_CREATE = "site.create";
export const PERMISSIONS_SUBSCRIBE = "site.subscribe";
export const PERMISSIONS_DEFAULT_SET = "user.default_set";

export const AUTH_SCHEME_OPEN = "open";
export const AUTH_SCHEME_REQUEST = "request";
export const AUTH_SCHEME_INVITE = "invite";

export const ROLES_ALL = "all";
export const ROLES_SHAREABLE = "shareable";

export const permissions: string[] = [PERMISSIONS_HUB_USER];
