import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  tap,
  timeout,
} from "rxjs/operators";
import { of } from "rxjs";

import { AppConfig } from "config";
import { authService, registerProfile } from "services/Auth";
import { googleLoginService } from "services/Auth";
import { snackbarService } from "services/Snackbar";

import * as routingConstants from "lib/routing";

export interface AutoCompleteOption {
  categoryTitle: string;
  categoryId: string;
  subcategoryId?: string;
  subcategoryTitle?: string;
}

export function loginEpic(action$, state$, deps) {
  return action$.pipe(
    mergeMap(
      (payload: {
        history: any;
        saveInfo: boolean;
        values: {
          email: string;
          password: string;
        };
      }) => {
        const { saveInfo, values, history } = payload;
        return authService.login(values, saveInfo).pipe(
          map((data) => {
            snackbarService.next({
              message: "You have successfully logged in.",
              show: true,
              type: "success",
            });
          }),
          timeout(1000),
          mergeMap((val) => {
            return of().pipe(
              tap(() => {
                if (window.location.hostname === AppConfig.adminHost) {
                  window.location.href = "/";
                } else {
                  window.location.href = "/dashboard";
                }
              }),
            );
          }),
          catchError((err) => {
            snackbarService.next({
              message: "You were unable to login with that name and password.",
              show: true,
              type: "error",
            });
            return of({});
          }),
        );
      },
    ),
  );
}

export function forgotPasswordEpic(action$, state$, deps) {
  return action$.pipe(
    mergeMap(
      (payload: {
        values: { email: string; password: string };
        setIndex: any;
      }) => {
        const { values, setIndex } = payload;

        return authService.forgotPassword(values).pipe(
          tap(() => {
            setIndex(2);
            snackbarService.next({
              message:
                "A key has been sent to your email address on record. Please use it to generate a new password.",
              show: true,
              type: "success",
            });
          }),
          catchError(() => {
            snackbarService.next({
              message: "You were unable to obtain a key.",
              show: true,
              type: "error",
            });
            return of({});
          }),
        );
      },
    ),
  );
}

export function resetPasswordEpic(action$, state$, deps) {
  return action$.pipe(
    mergeMap(
      (payload: {
        values: {
          email: string;
          password: string;
          confirm: string;
          key: string;
        };
        setIndex: any;
      }) => {
        const { values, setIndex } = payload;
        return authService.resetPassword(values).pipe(
          tap(() => {
            setIndex(0);
            snackbarService.next({
              message: "You have successfully reset your password.",
              show: true,
              type: "success",
            });
          }),
          catchError(() => {
            snackbarService.next({
              message: "You were unable to reset your password.",
              show: true,
              type: "error",
            });
            return of({});
          }),
        );
      },
    ),
  );
}

export function googleLoginEpic(action$, state$, deps) {
  return action$.pipe(
    mergeMap(({ history }) => googleLoginService.main(history)),
  );
}
